import React, { useEffect, useState } from 'react';
import Video from '../../assets/V1.mp4'
import './date.css' 
import { useTranslation } from 'react-i18next';

const Countdown = () => {
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const destination = new Date('2025-06-16T23:59:59').getTime();

        const interval = setInterval(() => {
            const now = new Date().getTime();
            const difference = destination - now;

            if (difference < 0) {
                clearInterval(interval);
                // Compte à rebours terminé, effectuez une action si nécessaire
            } else {
                const newDays = Math.floor(difference / (1000 * 60 * 60 * 24));
                const newHours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const newMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
                const newSeconds = Math.floor((difference % (1000 * 60)) / 1000);

                setDays(newDays);
                setHours(newHours);
                setMinutes(newMinutes);
                setSeconds(newSeconds);
            }
        }, 1000);

        return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage du composant
    }, []);

     const { t, i18n } = useTranslation();
        const [isDataReady, setIsDataReady] = useState(false);
    
        useEffect(() => {
            if (i18n.language && !isDataReady) {
                setIsDataReady(true);
            }
        }, [i18n.language, isDataReady]);

    return (
       

            <div className="video__section">
                <video className="video__background" autoPlay loop muted>
                    <source src={Video} type="video/mp4" />
                </video>
                <div className="content">
                    <div className='data'>
                        <h2 className='text-white '>{t('produit.prod21')} </h2>
                    </div>
                    <div className='clock__wrapper d-flex align-items-center '>
                        <div className='clock__data d-flex align-items-center'>
                            <div className='text-center'>
                                <h1 className='text-white  mb-2'>{days}</h1>
                                <h5 className='text-white '>Jours</h5>
                            </div>
                            <span className='text-white point'>:</span>
                        </div>
                        <div className='clock__data d-flex align-items-center gap-3'>
                            <div className='text-center'>
                                <h1 className='text-white  mb-2'>{hours}</h1>
                                <h5 className='text-white '>Heures</h5>
                            </div>
                            <span className='text-white point'>:</span>
                        </div>
                        <div className='clock__data d-flex align-items-center gap-3'>
                            <div className='text-center'>
                                <h1 className='text-white  mb-2'>{minutes}</h1>
                                <h5 className='text-white '>Minutes</h5>
                            </div>
                            <span className='text-white point'>:</span>
                        </div>
                        <div className='clock__data d-flex align-items-center gap-3'>
                            <div className='text-center'>
                                <h1 className='text-white  mb-2'>{seconds}</h1>
                                <h5 className='text-white '>Secondes</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        
    );
};

export default Countdown;
