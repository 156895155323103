import React, { useState, useEffect, useRef } from 'react';


import { Helmet } from 'react-helmet';
import '../styles/home.css';

import { useNavigate } from 'react-router-dom';


import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import Service from '../components/service/service';
import Realisation from '../components/Ressource/Realisation';



import img from '../assets/images/home.jpeg'
import img2 from '../assets/images/banner/call3.jpg'

import { useTranslation } from 'react-i18next';



const Home = () => {

  const navigate = useNavigate()


  const { t, i18n } = useTranslation();
  const [isDataReady, setIsDataReady] = useState(false);


  useEffect(() => {
    if (i18n.language && !isDataReady) {
      setIsDataReady(true);
    }
  }, [i18n.language, isDataReady]);



  const [visible, setVisible] = useState(false); // State pour gérer la visibilité de la boîte

  const [isvisible, setIsVisible] = useState(false);

  const boxRefs = useRef(null);

  const boxRef = useRef([]);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      // Vérifier si l'élément est dans la vue
      if (boxRefs.current && boxRefs.current.offsetTop < scrollTop + windowHeight) {
        // Afficher la boîte après un délai
        setTimeout(() => {
          setVisible(true);
        }, 1500); // Délai de 1 seconde pour l'animation
      }
    };

    // Écouter les événements de scroll
    window.addEventListener('scroll', handleScroll);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Le tableau vide [] assure que cet effet s'exécute uniquement une fois après le montage initial

  const boxVariants = {
    hidden: { opacity: 0, x: -500 },
    visible: { opacity: 1, x: 0 },
  };




  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 1500);
  }, []);

  const boxVariants1 = {
    hidden: { opacity: 0, x: 500 },
    visible: { opacity: 1, x: 0 },
  };

  const boxVariants2 = {
    hidden: { opacity: 0, x: 1500 },
    visible: { opacity: 1, x: 1 },
  };

  const boxVariants3 = {
    hidden: { opacity: 0, x: 2000 },
    visible: { opacity: 1, x: 1 },
  };


  return <>
    <Helmet>
      <title>Accueil </title>
      <meta name="description" content=" Bienvenue sur Phenictec  " />
    </Helmet>

    <section className="hero__section ">

      <div className='home'>
        <motion.div
          initial="hidden"
          animate={isvisible ? 'visible' : 'hidden'}
          variants={boxVariants1}
          transition={{ duration: 1, delay: 0 }}
          ref={(el) => (boxRefs.current = el)}>
          <h2 className='text-white'>{t('home.title1')}</h2>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={isvisible ? 'visible' : 'hidden'}
          variants={boxVariants2}
          transition={{ duration: 1, delay: 1 }}
          ref={(el) => (boxRefs.current = el)}>
          <p className='text-white'>{t('home.title2')}</p>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={isvisible ? 'visible' : 'hidden'}
          variants={boxVariants3}
          transition={{ duration: 1, delay: 2 }}
          ref={(el) => (boxRefs.current = el)}>
          <button >
            <Link to='/produit'>
              {t('home.title3')}
            </Link>
          </button>
        </motion.div>
      </div>

    </section>


    <section className="overflow-hidden">
  <Container>
    <Row className="justify-content-center align-items-center">
      {/* Colonne pour l'image */}
      <Col lg="6" md="6" sm="12" className="p-3">
        <motion.div
          initial="hidden"
          animate={visible ? "visible" : "hidden"}
          variants={boxVariants}
          transition={{ duration: 0.5 }}
          ref={(el) => (boxRefs.current = el)}
        >
          <div className="m-auto text-center homeimg">
            <img src={img} className="img-fluid rounded-start p-2" alt="..." />
          </div>
        </motion.div>
      </Col>

      {/* Colonne pour le texte */}
      <Col lg="6" md="8" sm="12">
        <div className="p-3 d-flex justify-content-center align-items-center text-center pub">
          <motion.div
            initial="hidden"
            animate={visible ? "visible" : "hidden"}
            variants={boxVariants1}
            transition={{ duration: 0.5 }}
            ref={(el) => (boxRefs.current = el)}
          >
            <span>{t("home.titre")}</span>
          </motion.div>
        </div>
      </Col>
    </Row>
  </Container>
</section>






    <section className="padser">
      <div className="container-xxl">

        <Service />

      </div>
    </section>

    <section className="padreal ">
      <div className="container-xxl">
        <div className=' text-center mb-5 realisation'>
          <h1 >{t('home.realisation')} </h1>
        </div>
        <Realisation />

      </div>
    </section>
  </>;
};

export default Home;
