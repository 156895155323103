import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import CommoSection from '../UI/CommoSection';
import { Container, Row, Col } from 'reactstrap';
import { motion } from 'framer-motion';
import './ressource.css'
import { useLocation, Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import B1 from '../../assets/images/reseau/reseau4.jpeg';
import B2 from '../../assets/images/reseau/surveille.png';
import B3 from '../../assets/images/reseau/virt.jpeg';
import B4 from '../../assets/images/reseau/cisco.jpeg';
import B5 from '../../assets/images/reseau/cloud2.png';
import B6 from '../../assets/images/reseau/support.jpeg';

import b1 from '../../assets/images/reseau.jpg';
import b2 from '../../assets/images/reseau/inst.jpeg';

const Reseau = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [isDataReady, setIsDataReady] = useState(false);
  const [scrolling, setScrolling] = useState(true);
  const [visibleBox, setVisibleBox] = useState(Array(5).fill(false));
  const isSmallScreen = useMediaQuery('(max-width:768px)'); // Détecte si l'écran est petit

  const boxRefs = useRef([]);

  useEffect(() => {
    if (i18n.language && !isDataReady) {
      setIsDataReady(true);
    }
  }, [i18n.language, isDataReady]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;

      boxRefs.current.forEach((ref, index) => {
        if (ref && ref.offsetTop < scrollTop + windowHeight) {
          const timeoutId = setTimeout(() => {
            setVisibleBox((prevVisibleBox) => {
              const newVisibleBox = [...prevVisibleBox];
              newVisibleBox[index] = true;
              return newVisibleBox;
            });
          }, index * 1000);
          timeoutIds.push(timeoutId);
        }
      });
    };

    const timeoutIds = [];
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, []);

  const boxesData = [
    { title: t('home.res') },
    { entete: t('service3.reseau1') }
  ];

  const boxVariant = {
    hidden: { opacity: 0, x: -500 },
    visible: { opacity: 1, x: 0 }
  };

  const handleLinksiteweb = () => { window.location.href = '/siteweb'; };
  const handleLinkreseau = () => { window.location.href = '/reseau'; };
  const handleLinkinfogra = () => { window.location.href = '/infographie_video'; };
  const handleLinkapi = () => { window.location.href = '/api'; };
  const handleLinkmobile = () => { window.location.href = '/application-mobile'; };

  const handleContainerClick = () => {
    setScrolling((prevScrolling) => !prevScrolling);
  };

  
  const handleLink = (path) => () => {
    window.location.href = path;
  };


  return (
    <>
      <Helmet>
        <title>Services - Réseaux Informatique </title>
        <meta name="description" content=" Réseaux Informatique" />
      </Helmet>
      <CommoSection title={t('home.res')} />
      <section className="servi m-3">
        <Container>
          <Row className="service_p">
            <Col lg="7">
              <div className="m-auto text-center serimg">
                <img src={b1} className="img-fluid rounded-start p-2" alt="..." />
              </div>
            </Col>
            <Col lg="5" className="ser">
              <div className="card-body">
                <div className="text-center">
                  <h1 className="card-title">{t('service1.solu')}</h1>
                </div>
                <ul className="navlink">
                  <li>
                    <Link onClick={handleLinksiteweb} className={location.pathname === '/siteweb' ? 'active' : 'inactive'}>
                      {t('home.siteweb')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleLinkmobile} className={location.pathname === '/application-mobile' ? 'active' : 'inactive'}>
                      {t('home.mob')}
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={handleLink('/logiciel')}
                      className={location.pathname === '/logiciel' ? 'active' : 'inactive'}
                    >
                      {t('home.log')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleLinkreseau} className={location.pathname === '/reseau' ? 'active' : 'inactive'}>
                      {t('home.res')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleLinkinfogra} className={location.pathname === '/infographie_video' ? 'active' : 'inactive'}>
                      {t('home.info')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={handleLinkapi} className={location.pathname === '/api' ? 'active' : 'inactive'}>
                      {t('home.ap')}
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="mt-5">
            <Col lg="7">
              <div className="p-2">
                {boxesData.map((box, index) => (
                  <motion.div
                    key={index}
                    className="pt-2"
                    initial="hidden"
                    animate={visibleBox[index] ? "visible" : "hidden"}
                    variants={boxVariant}
                    transition={{ duration: 0.5 }}
                    ref={(el) => (boxRefs.current[index] = el)}
                  >
                    <div className="text-center align-items-center">
                      <span className="title">{box.title}</span>
                    </div>
                    <span>{box.entete}</span>
                  </motion.div>
                ))}
              </div>
            </Col>
            <Col lg="5">
              <div className="d-flex justify-content-center align-items-center imgsite" style={{ height: '100%' }}>
                <img src={b2} className="img-fluid" alt="..." />
              </div>
            </Col>
          </Row>
        </Container>

        <div className="text-center align-items-center mt-5 mb-3">
          <span className="title">{t('service3.reseau14')}</span>
        </div>

        <Marquee play={scrolling} pauseOnHover onClick={handleContainerClick}  >
          <Container>
            <Row className="d-flex justify-content-center align-items-center reseau">
              <Col lg="6">
                <div className="p-2">
                  <span className="titre">{t('service3.reseau2')}</span>
                  <span className="text">{t('service3.reseau3')}</span>
                </div>
              </Col>
              <Col lg="6">
                <div className="d-flex justify-content-center align-items-center imgreseau" style={{ height: '100%' }}>
                  <img src={B1} className="img-fluid" alt="..." />
                </div>
              </Col>
            </Row>
            {isSmallScreen && (
              <div className="text-center align-items-center mt-3">
                <span className="message">   {t('service3.message')} </span>
              </div>
            )}
          </Container>

          <Container>
            <Row className="d-flex justify-content-center align-items-center reseau1">
              <Col lg="6">
                <div className="p-2">
                  <div >
                    <span className="titre">{t('service3.reseau4')}</span>
                  </div>
                  <span className="text">{t('service3.reseau5')}</span>
                </div>
              </Col>
              <Col lg="6">
                <div className="d-flex justify-content-center align-items-center imgreseau" style={{ height: '100%' }}>
                  <img src={B2} className="img-fluid" alt="..." />
                </div>
              </Col>
            </Row>
            {isSmallScreen && (
              <div className="text-center align-items-center mt-3">
                <span className="message">   {t('service3.message')} </span>
              </div>
            )}
          </Container>

          <Container>
            <Row className="d-flex justify-content-center align-items-center reseau">
              <Col lg="6">
                <div className="p-2">
                  <div>
                    <span className="titre">{t('service3.reseau6')}</span>
                  </div>
                  <span className="text">{t('service3.reseau7')}</span>
                </div>
              </Col>
              <Col lg="6">
                <div className="d-flex justify-content-center align-items-center imgreseau" style={{ height: '100%' }}>
                  <img src={B4} className="img-fluid" alt="..." />
                </div>
              </Col>
            </Row>
            {isSmallScreen && (
              <div className="text-center align-items-center mt-3">
                <span className="message">  {t('service3.message')}</span>
              </div>
            )}
          </Container>

          <Container>
            <Row className="d-flex justify-content-center align-items-center reseau1">
              <Col lg="6">
                <div className="p-2">
                  <div >
                    <span className="titre">{t('service3.reseau8')}</span>
                  </div>
                  <span className="text">{t('service3.reseau9')}</span>
                </div>
              </Col>
              <Col lg="6">
                <div className="d-flex justify-content-center align-items-center imgreseau" style={{ height: '100%' }}>
                  <img src={B3} className="img-fluid" alt="..." />
                </div>
              </Col>
            </Row>
            {isSmallScreen && (
              <div className="text-center align-items-center mt-3">
                <span className="message">   {t('service3.message')}</span>
              </div>
            )}
          </Container>

          <Container>
            <Row className="d-flex justify-content-center align-items-center reseau">
              <Col lg="6">
                <div className="p-2">
                  <div >
                    <span className="titre">{t('service3.reseau10')}</span>
                  </div>
                  <span className="text">{t('service3.reseau11')}</span>
                </div>
              </Col>
              <Col lg="6">
                <div className="d-flex justify-content-center align-items-center imgreseau" style={{ height: '100%' }}>
                  <img src={B5} className="img-fluid" alt="..." />
                </div>
              </Col>
            </Row>
            {isSmallScreen && (
              <div className="text-center align-items-center mt-3">
                <span className="message">   {t('service3.message')}</span>
              </div>
            )}
          </Container>

          <Container>
            <Row className="d-flex justify-content-center align-items-center reseau1">
              <Col lg="6">
                <div className="p-2">
                  <div >
                    <span className="titre">{t('service3.reseau12')}</span>
                  </div>
                  <span className="text">{t('service3.reseau13')}</span>
                </div>
              </Col>
              <Col lg="6">
                <div className="d-flex justify-content-center align-items-center imgreseau" style={{ height: '100%' }}>
                  <img src={B6} className="img-fluid" alt="..." />
                </div>
              </Col>
            </Row>
            {isSmallScreen && (
              <div className="text-center align-items-center mt-3">
                <span className="message">   {t('service3.message')}</span>
              </div>
            )}
          </Container>
        </Marquee>
      </section>
    </>
  );
}

export default Reseau;
