import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Progress } from 'reactstrap';
import { toast } from 'react-toastify';
import { db, storage } from '../firebase.config';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const Upload = () => {
    const [title, setTitle] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.name.endsWith('.zip')) {
            setFile(selectedFile);
        } else {
            toast.error('Veuillez sélectionner un fichier .zip');
            setFile(null);
        }
    };

    const addProgram = async (e) => {
        e.preventDefault();
    
        if (!file) {
            toast.error('Aucun fichier sélectionné');
            return;
        }
    
        setLoading(true);
        setUploadProgress(0);
    
        try {
            // Vérification pour éviter l'erreur
            const sanitizedFileName = file ? file.name.replace(/[^a-zA-Z0-9.]/g, "_") : "default.zip";
            const programRef = ref(storage, `Programmes/${sanitizedFileName}`);
            const uploadTask = uploadBytesResumable(programRef, file);
    
            uploadTask.on(
                'state_changed',
                (snapshot) => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    setUploadProgress(progress);
                },
                (error) => {
                    toast.error("Échec de l'upload : " + error.message);
                    setLoading(false);
                },
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    await addDoc(collection(db, 'programmes'), {
                        titre: title,
                        date: serverTimestamp(),
                        programmeURL: downloadURL,
                    });
                    toast.success('Programme ajouté avec succès');
                    navigate('/dashboard__admin/list_real');
                }
            );
        } catch (error) {
            toast.error("Erreur lors de l'ajout du programme");
            setLoading(false);
        }
    };

    return (
        <section>
            <Container>
                <Row>
                    <Col lg="12">
                        {loading ? (
                            <>
                                <h4 className="py-3">Chargement...</h4>
                                <Progress value={uploadProgress} />
                            </>
                        ) : (
                            <>
                                <h4 className="mb-5 mt-1">Ajouter un Programme</h4>
                                <Form onSubmit={addProgram}>
                                    <FormGroup className="form__group">
                                        <input
                                            type="file"
                                            accept=".zip"
                                            onChange={handleFileChange}
                                            required
                                        />
                                    </FormGroup>

                                    <FormGroup className="form__group">
                                        <input
                                            type="text"
                                            placeholder="Nom du programme"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            required
                                        />
                                    </FormGroup>

                                    <button className="btn__submit" type="submit" disabled={loading}>
                                        Ajouter
                                    </button>
                                </Form>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Upload;
