import React, { useState, useEffect } from 'react';
import Helmet from '../components/Helmet/Helmet';
import CommoSection from '../components/UI/CommoSection';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Icon from '../assets/images/icon.png'
import video from '../assets/video_pub.mp4';
import Countdown from '../components/DateExpiration';
import useGetsData from '../custom-hooks/useGetsData';



const Produit = () => {
    const { t, i18n } = useTranslation();
    const [isDataReady, setIsDataReady] = useState(false);

    const { data: programData, loadingprog } = useGetsData('programmes')
    useEffect(() => {
        if (i18n.language && !isDataReady) {
            setIsDataReady(true);
        }
    }, [i18n.language, isDataReady]);


    const [language, setLanguage] = useState('');

    useEffect(() => {
        // Check the language in localStorage
        const lang = localStorage.getItem('i18nextLng');
        setLanguage(lang);
    }, []);


    return (
        <Helmet title="Contact">


            <Countdown />



            <section className="contact__prod" >

                <div className="lien">

                    <h2 className="mb-0">{t('produit.prod22')}</h2>
                    <a href={`/${t('produit.prod23')}`} className=" text-primary">
                        {t('produit.prod24')}
                    </a>

                </div>


                <Container>
                    <Row>
                        <Col lg='6' className=''>

                            <div className="d-flex  align-items-center  auth">
                                <img src={Icon} className="auth_icon rounded-circle p-1" alt="AuthentLock Icon" />
                                <h2 className=" text-center mt-2">{t('produit.prod5')}<span className='text-warning'>({t('produit.prod20')} )</span> </h2>
                            </div>

                            <p className='desc'>{t('produit.prod2')}</p>
                            
                            <p className='cssnb'>  {t('produit.prodNB')}</p>

                            <Container>
                                <Row className='logiciel '>
                                    {loadingprog ? (
                                        <h3 className="py-5 fw-bold">Chargement...</h3>
                                    ) : (
                                        programData.map((item) => (
                                            <Col lg='6' >

                                                <div className='authL'>
                                                    <a
                                                        href={item.programmeURL}
                                                        download
                                                        className="btn  btn-primary"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        {t('produit.prod3')} {item.titre}
                                                    </a>
                                                </div>
                                            </Col>
                                        ))
                                    )}

                                </Row>
                            </Container>

                        </Col>

                        <Col lg="6" className="d-flex justify-content-center align-items-center">
                            <video
                                className="w-100 h-auto"
                                autoPlay
                                loop
                                muted
                                style={{ objectFit: "cover" }}
                            >
                                <source src={video} type="video/mp4" />
                                Votre navigateur ne supporte pas la lecture de vidéos.
                            </video>
                        </Col>

                    </Row>
                </Container>


                <Container className='fonction'>
                    <h1 className=" text-center title ">{t('produit.prod4')} </h1>
                    <Row >
                        <Col lg='4'>
                            <h2> {t('produit.prod6')}</h2>
                            <p className='text-para'>{t('produit.prod7')}</p>
                        </Col>

                        <Col lg='4'>
                            <h2>{t('produit.prod8')} </h2>
                            <p className='text-para'>{t('produit.prod9')} </p>
                        </Col>

                        <Col lg='4'>
                            <h2> {t('produit.prod10')} </h2>
                            <p className='text-para'>{t('produit.prod11')} </p>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col lg='4'>
                            <h2>{t('produit.prod12')} </h2>
                            <p className='text-para'>{t('produit.prod13')} </p>
                        </Col>

                        <Col lg='4'>
                            <h2>{t('produit.prod14')} </h2>
                            <p className='text-para'>{t('produit.prod15')} </p>
                        </Col>

                        <Col lg='4'>
                            <h2>{t('produit.prod16')}  </h2>
                            <p className='text-para'>{t('produit.prod17')} </p>
                        </Col>
                    </Row>

                    <Row className='mt-4'>
                        <Col lg='4'>
                            <h2>{t('produit.prod18')} </h2>
                            <p className='text-para'> {t('produit.prod19')} </p>
                        </Col>

                    </Row>

                </Container>
            </section>
        </Helmet>
    );
}

export default Produit;
