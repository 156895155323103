import React, { useRef } from 'react'

import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';



const admin__nav = [
  {
    display: 'Liste des Messages',
    path: '/dashboard__admin'
  },
  {
    display: 'Liste des Utilisateurs',
    path: '/dashboard__admin/users'
  },
  {
    display: 'Ajouter des Realisations',
    path: '/dashboard__admin/ajouter_real'
  },
  {
    display: 'Listes des Realisations',
    path: '/dashboard__admin/list_real'
  },
  {
    display: 'Inscription',
    path: '/dashboard/signup'
  },
];
const List_real = () => {

  const { data: productsData, loading } = useGetsData('realisation')

  const { data: programData,loadingprog } = useGetsData('programmes')

  //==== firebase firstore realtime data uptate

  const deleteProduct = async (id) => {
    await deleteDoc(doc(db, 'realisation', id))
    toast.success('Suppression effectuer !')

    
  }

  const menuRef = useRef(null);

  

  const menuToggle = () => menuRef.current.classList.toggle('active__menu');

  return (
    <>
      <section className="admin__menu p-0">
        <Container>
          <Row>
            <div className="admin__navigation navigation" ref={menuRef} onClick={menuToggle}>
              <ul className="admin__menu-list menu">
                {admin__nav.map((item, index) => (
                  <li className="admin__menu-item" key={index}>
                    <NavLink to={item.path} className={navclass => navclass.isActive ? 'active__admin-menu' : ''}>
                      {item.display}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>

            <div className="nav__icons">
              <div className="mobile__menu">
                <span onClick={menuToggle}>
                  <i className="ri-menu-line"></i>
                </span>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <Container>
        <Row>
          <Col lg='12'>
            <table className='table mt-5'>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Titre</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (<h3 className='py-5 fw-bold'>loading....</h3>) : (productsData.map(item => (
                  <tr key={item.id}>
                    <td><img src={item.imgUrl} alt="" /> </td>
                    <td>{item.titre} </td>
                    <td>{item.shortDesc} </td>
                    <td>
                      <button onClick={() => { deleteProduct(item.id) }} className="btn btn-danger">Delete</button>
                    </td>
                  </tr>
                )))
                }
              </tbody>
            </table>
          </Col>
        </Row>
      </Container>

      <Container>
      <Row>
        <Col lg="12">
          <table className="table mt-5">
            <thead>
              <tr>
                <th>Programme</th>
                <th>Titre</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loadingprog ? (
                <h3 className="py-5 fw-bold">Chargement...</h3>
              ) : (
                programData.map((item) => (
                  <tr key={item.id}>
                    <td>
                      {/* Affichage d'un lien pour télécharger le fichier ZIP */}
                      <a
                        href={item.programmeURL}
                        download
                        className="btn btn-info"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Télécharger le fichier ZIP
                      </a>
                    </td>
                    <td>{item.titre}</td>
                    <td>
                      <button
                        onClick={() => {
                          deleteProduct(item.id);
                        }}
                        className="btn btn-danger"
                      >
                        Supprimer
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default List_real
