import React from 'react'
import './style.css'
import { Col, Container, Row } from 'reactstrap'
import { motion } from 'framer-motion';



import B1 from '../../assets/images/manuel/1.png'
import B2 from '../../assets/images/manuel/2.png'
import INS1 from '../../assets/images/manuel/Inst1.png'
import INS2 from '../../assets/images/manuel/inst2.png'
import B6 from '../../assets/images/manuel/A.png'
import B7 from '../../assets/images/manuel/7.png'
import B8 from '../../assets/images/manuel/8.png'
import B9 from '../../assets/images/manuel/auth.png'
import B10 from '../../assets/images/manuel/cmd.png'

import youtube from '../../assets/images/manuel/youtub.png'

const Manuel = () => {



  return (
    <>
      <section className='manuel'>
        <div className='align-items-center text-center mb-2'>
          <h2 >MANUEL D' INSTALLATION </h2>
        </div>
        <p>Pour installer AuthentLock, suivez attentivement les instructions du manuel. Chaque étape doit être respectée pour garantir une installation correcte. Prenez le temps de bien comprendre chaque section avant de continuer.</p>


        <h3>1 - Installation de AutoHotkey</h3>
        <div>
          <p>AutoHotkey est un logiciel permettant d'automatiser des tâches sous Windows grâce à un langage de script simple. Il sert à créer des raccourcis clavier, automatiser des actions répétitives et améliorer la productivité.</p>
          <p>Télécharge AutoHotkey sur le site officiel au <a className="lienauto" href="https://www.autohotkey.com/" target="_blank" rel="noopener noreferrer">https://www.autohotkey.com/</a>.</p>
          <Container className='mt-3'>
            <Row className="justify-content-center ">
              <Col lg="6" className="text-center">
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                  <img src={B1} className="img-fluid" alt="..." />
                  <p className="mt-2">Cliquer sur Install</p>
                </div>
              </Col>
              <Col lg="6" className="text-center col">
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                  <img src={B2} className="img-fluid" alt="..." />
                  <p className="mt-2">Bien installer et vous ferme le logiciel</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>


        <h3>2 - Instalation de AuthentLock</h3>
        <Container className='mt-5'>
          <Row className="justify-content-center">
            <Col lg="5" className="text-center col">
              <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                <img src={INS1} className="img-fluid" alt="..." />
                <p className="mt-2">Cliquer  Suivant</p>
              </div>
            </Col>
            <Col lg="5" className="text-center col">
              <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                <img src={INS2} className="img-fluid" alt="..." />
                <p className="mt-2"> Cliquer Installer et apres vous ferme le logiciel</p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className="mb-5 mt-5 nben">
          <Row className="justify-content-centr">
            <Col lg="6" className='cal' >
              <div >
                <p> <span className="nb">NB : </span> <p className='para'>  • Pour vous inscrire et accéder à AuthentLock, il est essentiel d’utiliser une adresse e-mail Google valide. Une fois inscrit avec votre adresse e-mail Google, vous pourrez l'utiliser pour vous connecter facilement sur plusieurs appareils. Après une connexion, un identifiant de connexion de l' appareil vous sera envoyé à votre boîte e-mail. Ainsi, vous pourrez sécuriser plusieurs appareils avec une seule adresse e-mail, vous assurant une expérience fluide et pratique sur tous vos dispositifs.</p></p>
                <p className='para mt-2' >• En appuyant sur 'Oui', vous autorisez l'exécution de la commande en tant qu'administrateur, ce qui permet au programme d'apporter les modifications nécessaires à votre système. Cette étape est requise pour garantir le bon fonctionnement de certaines actions nécessitant des privilèges élevés pour effectuer des tâches du logiciel.</p>
              </div>
            </Col>
            <Col lg="6" className="text-center  cal">
              <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                <img src={B10} className="img-fluid" alt="..." />
              </div>
            </Col>
          </Row>
        </Container>

        <Container className='mt-3'>
          <Row className="justify-content-center ">
            <Col lg="6" className="text-center  cal">
              <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                <img src={B7} className="img-fluid" alt="..." />
                <p className="mt-4">Vous devez avoir une adresse e-mail Google valide avant de vous inscrire, car l'identifiant de connexion vous sera envoyé à cette adresse Email. </p>
              </div>
            </Col>
            <Col lg="6" className="text-center  cal">
              <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                <img src={B8} className="img-fluid" alt="..." />
                <p className="mt-4"> Après l'inscription, vous pouvez vous connecter grâce à cet e-mail. </p>
              </div>
            </Col>
          </Row>
        </Container>

        <Container className='mt-5'>
          <Row className="justify-content-center ">
            <Col lg="6" className="text-center  cal">
              <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                <img src={B6} className="img-fluid" alt="..." />
                <p className="mt-2">Page d' Acceuil</p>
              </div>
            </Col>
            <Col lg="6" className="text-center  cal">
              <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                <img src={B9} className="img-fluid" alt="..." />
                <p className="mt-2"> Page Authentication </p>
              </div>
            </Col>
          </Row>
        </Container>

        <div className="youtube mt-5 ">

          <p>Visitez notre page YouTube pour voir des vidéos et obtenir plus d'explications. </p>
          <a href="https://www.youtube.com/results?search_query=phenixtec" target="_blank" rel="noopener noreferrer" >
            <motion.div whileTap={{ scale: 1.2 }}  >
              <img src={youtube} className="img-fluid" alt="..." />
            </motion.div>
          </a>
        </div>

      </section>
    </>
  )
}

export default Manuel
