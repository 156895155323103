import { Route, Routes, Navigate } from 'react-router-dom';
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import Produit from '../pages/Produit';


import Reseau from '../components/service/reseau';
import Infographie from '../components/service/infographie';
import Api from '../components/service/api';
import Siteweb from '../components/service/developpement-site-web';
import Mobile from '../components/service/developpement-application-mobile';

import ProtectedRoute from './ProtectedRoute';
import Signup from '../Dashboard/Signup';
import Login from '../Dashboard/Login';
import Dashboard from '../Dashboard/dashboard';
import Upload from '../Dashboard_ADdmin/upload';

import Dashboard_admin from '../Dashboard_ADdmin/dashboard';
import Users from '../Dashboard_ADdmin/users';
import Ajouter_real from '../Dashboard_ADdmin/ajouter_real';
import List_real from '../Dashboard_ADdmin/List_real';
import NotFound from '../pages/Notfound';
import Logiciel from '../components/service/logiciel';
import Manuel from '../components/Manuel_util/manuel';
import Manuel_En from '../components/Manuel_util/manuel_en';

const Routers = () => {
  return (
    <Routes>
       <Route path="*" element={<NotFound />} /> {/* Redirige vers NotFound pour les URLs incorrectes */}
      <Route path="/" element={<Navigate to="home" />} />
      <Route path="home" element={<Home />} />
      <Route path="contact" element={<Contact />} />
      <Route path="produit" element={<Produit />} />

      <Route path="infographie_video" element={<Infographie />} />
      <Route path="siteweb" element={<Siteweb />} />
      <Route path="application-mobile" element={<Mobile />} />
      <Route path="reseau" element={<Reseau />} />
      <Route path="api" element={<Api />} />

      <Route path="logiciel" element={<Logiciel />} />
      <Route path="manuel" element={<Manuel />} />
      <Route path="manuelen" element={<Manuel_En />} />

      <Route path="/*" element={<ProtectedRoute />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="dashboard/signup" element={<Signup />} />
        <Route path="dashboard__admin" element={<Dashboard_admin />} />
        <Route path="dashboard__admin/users" element={<Users />} />
        <Route path="dashboard__admin/ajouter_real" element={<Ajouter_real />} />
        <Route path="dashboard__admin/list_real" element={<List_real />} />
        <Route path="dashboard__admin/upload" element={<Upload />} />
      </Route>

      <Route path="dashboard/signup" element={<Signup />} />
      <Route path="dashboard/login" element={<Login />} />
     
    </Routes>
  );
};

export default Routers;
