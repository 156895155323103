import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import useGetsData from '../custom-hooks/useGetsData';
import { db } from '../firebase.config';
import { doc, deleteDoc , } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import useAuth from '../custom-hooks/useAuth';
import '../styles/dashboard.css';


const admin__nav = [
  {
    display: 'Liste des Messages',
    path: '/dashboard__admin'
  },
  {
    display: 'Liste des Utilisateurs',
    path: '/dashboard__admin/users'
  },
  {
    display: 'Ajouter des Realisations',
    path: '/dashboard__admin/ajouter_real'
  },
  {
    display: 'Listes des Realisations',
    path: '/dashboard__admin/list_real'
  },
  {
    display: 'Inscription',
    path: '/dashboard/signup'
  },
];

const Users = () => {
  const { data: messages, loading } = useGetsData('utilisateur');
  const [messagesData, setMessagesData] = useState(messages);
  const navigate = useNavigate();

  useEffect(() => {
    setMessagesData(messages);
  }, [messages]);

  useEffect(() => {
    const sortedMessages = [...messages].sort((a, b) => b.Date - a.Date);
    setMessagesData(sortedMessages);
  }, [messages]);

  const deleteMessage = async (id) => {
    await deleteDoc(doc(db, 'Message', id));
    toast.success('Message deleted successfully!');
  };

  const headerRef = useRef(null);

  const menuRef = useRef(null);

  const { currentUser } = useAuth()

  const menuToggle = () => menuRef.current.classList.toggle('active__menu');

  return (
    <>
      
      <section className="admin__menu p-0 ">
        <Container>
          <Row>
            <div className="admin__navigation navigation" ref={menuRef} onClick={menuToggle}>
              <ul className="admin__menu-list menu">
                {
                  admin__nav.map((item, index) => (
                    <li className="admin__menu-item" key={index}>
                      <NavLink to={item.path} className={navclass =>
                        navclass.isActive ? 'active__admin-menu' : ''}>{item.display} </NavLink>
                    </li>
                  ))
                }
              </ul>
            </div>

            <div className="nav__icons">

              <div className="mobile__menu">
                <span onClick={menuToggle}>
                  <i class="ri-menu-line"></i></span>
              </div>
            </div>


          </Row>
        </Container>
      </section>

    <Container>
      <Row>
        <Col lg='12'>
          <div className="table-responsive mb-5 mt-5">
            <table className='table bordered'>
              <thead>
                <tr>
                  
                  <th style={{ width: 200 }}>Last Name</th>
                  <th style={{ width: 200 }}>Email</th>
                  <th style={{ width: 150 }}>Phone</th>
                  <th style={{ width: 200 }}>Responsabilité</th>
                  <th style={{ width: 200 }}>Date Creation</th>
                  <th style={{ width: 100 }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <h3 className='py-5 fw-bold'>Loading...</h3>
                ) : (
                  messagesData.map(item => (
                    <tr key={item.id}>
                      <td>{item.FirstName}</td>
                      <td>{item.LastName}</td>
                      <td>{item.Phone}</td>
                      <td>{item.email}</td>
                      <td>
                        {item.Date ? `${item.Date.toDate().toLocaleString('en-GB', { timeZone: 'UTC' })} UTC ` : ""}
                      </td>
                      <td>
                        <button onClick={() => { deleteMessage(item.id) }} className="btn btn-danger">Delete</button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Users;
