import React from 'react'
import './style.css'
import { Col, Container, Row } from 'reactstrap'
import { motion } from 'framer-motion';

import B1 from '../../assets/images/manuel/1.png'
import B2 from '../../assets/images/manuel/2.png'
import INS1 from '../../assets/images/manuel/Inst1.png'
import INS2 from '../../assets/images/manuel/inst2.png'
import B6 from '../../assets/images/manuel/AE.png'
import B7 from '../../assets/images/manuel/4.png'
import B8 from '../../assets/images/manuel/5.png'
import B9 from '../../assets/images/manuel/auth.png'
import B10 from '../../assets/images/manuel/cmd.png'
import youtube from '../../assets/images/manuel/youtub.png'

const Manual_En = () => {

  return (
    <>
      <section className='manuel'>
        <div className='align-items-center text-center mb-2'>
          <h2>INSTALLATION MANUAL</h2>
        </div>
        <p>To install AuthentLock, carefully follow the instructions in the manual. Each step must be followed to ensure correct installation. Take the time to fully understand each section before proceeding.</p>

        <div className='mt-3'>
          <h3>1 - Installing AutoHotkey</h3>
          <div>
            <p>AutoHotkey is a software that allows you to automate tasks on Windows using a simple scripting language. It helps create keyboard shortcuts, automate repetitive actions, and enhance productivity.</p>
            <p>Download AutoHotkey from the official website at <a className="linkauto" href="https://www.autohotkey.com/" target="_blank" rel="noopener noreferrer">https://www.autohotkey.com/</a>.</p>
            <Container className='mt-3'>
              <Row className="justify-content-center">
                <Col lg="6" className="text-center cal">
                  <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                    <img src={B1} className="img-fluid" alt="..." />
                    <p className="mt-2">Click on Install</p>
                  </div>
                </Col>
                <Col lg="6" className="text-center cal">
                  <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                    <img src={B2} className="img-fluid" alt="..." />
                    <p className="mt-2">Successfully install and then close the software</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>


          <h3>2 - Installing AuthentLock</h3>
          <Container className='mt-5'>
            <Row className="justify-content-center ">
              <Col lg="5" className="text-center cal">
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                  <img src={INS1} className="img-fluid" alt="..." />
                  <p className="mt-2">Click Next</p>
                </div>
              </Col>
              <Col lg="5" className="text-center  cal">
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                  <img src={INS2} className="img-fluid" alt="..." />
                  <p className="mt-2">Click Install and then close the software</p>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="mb-5 mt-5 nben">
            <Row className="justify-content-center">
              <Col lg="6" className='cal'>
                <div>
                  <p> <span className="nb">Note: </span>
                    <p className='para'> • To sign up and access AuthentLock, it is essential to use a valid Google email address. Once registered with your Google email, you can use it to log in easily on multiple devices. After logging in, a device login ID will be sent to your email inbox. This way, you can secure multiple devices with a single email address, ensuring a smooth and convenient experience across all your devices.</p>
                  </p>
                  <p className='para mt-2'>• By clicking 'Yes,' you authorize the command to run as administrator, allowing the program to make necessary changes to your system. This step is required to ensure that certain actions needing elevated privileges to run the software work properly.</p>
                </div>
              </Col>
              <Col lg="6" className="text-center cal">
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                  <img src={B10} className="img-fluid" alt="..." />
                </div>
              </Col>
            </Row>
          </Container>

          <Container className='mt-3'>
            <Row className="justify-content-center">
              <Col lg="6" className="text-center cal">
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                  <img src={B7} className="img-fluid" alt="..." />
                  <p className="mt-4">You must have a valid Google email address before registering, as the login credentials will be sent to this address.</p>
                </div>
              </Col>
              <Col lg="6" className="text-center  cal">
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                  <img src={B8} className="img-fluid" alt="..." />
                  <p className="mt-4">After registration, you can log in with this email address.</p>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className='mt-5'>
            <Row className="justify-content-center">
              <Col lg="6" className="text-center cal">
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                  <img src={B6} className="img-fluid" alt="..." />
                  <p className="mt-2">Home Page</p>
                </div>
              </Col>
              <Col lg="6" className="text-center  cal">
                <div className="d-flex justify-content-center align-items-center flex-column" style={{ height: '100%' }}>
                  <img src={B9} className="img-fluid" alt="..." />
                  <p className="mt-2">Authentication Page</p>
                </div>
              </Col>
            </Row>
          </Container>

          <div className="youtube mt-5 ">

            <p>Visit our YouTube page to watch videos and get more explanations. </p>
            <a href="https://www.youtube.com/results?search_query=phenixtec" target="_blank" rel="noopener noreferrer" >
              <motion.div whileTap={{ scale: 1.2 }}  >
                <img src={youtube} className="img-fluid" alt="..." />
              </motion.div>
            </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Manual_En
